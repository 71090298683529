<template>
  <div class="page">
    <form-panel ref="formPanel">
      <el-form-item label="问卷名称">
        <v-input
          placeholder="请输入问卷名称"
          v-model="form.questionnaireName"
        ></v-input>
      </el-form-item>
      <div class="addBtn">
        <v-button text="新增问题" @click="toAddSelect" />
      </div>
      <div class="workSheet">
        <v-table :tableData="form.requestList" ref="vTable" :headers="headers">
          <template #operateTeSlot="scope">
            <div class="opera-btn">
              <v-button
                text="编辑"
                type="text"
                @click="toEdit(scope.row.row, scope.row.$index)"
              />
              <v-button
                text="删除"
                type="text"
                @click="toDelete(scope.row.row, scope.row.$index)"
              />
            </div>
          </template>
        </v-table>
      </div>
      <el-form-item label="发布状态">
        <v-select :options="statusType" v-model="form.status" />
      </el-form-item>
      <el-form-item label="">
        <v-button
          style="width: 168px"
          type="success"
          text="保存"
          @click="submit()"
        />
      </el-form-item>
      <v-dialog
        :title="title"
        v-model="isdialog"
        @cancel="cancel"
        @confirm="confirm"
        cancelTxt="关闭"
        sureTxt="保存"
      >
        <el-form-item label="名称">
          <v-input
            placeholder="请输入名称"
            v-model="item.problemName"
          ></v-input>
        </el-form-item>
        <el-form-item label="类型">
          <v-select
            :options="quType"
            v-model="item.problemType"
            @change="changeQuType"
          />
        </el-form-item>
        <el-form-item
          v-for="(oItem, index) in item.selectList"
          :key="index"
          :label="'选项' + letterList[index]"
        >
          <div class="selectItem">
            <v-input placeholder="请输入" v-model="oItem.value"></v-input>
            <div class="handle">
              <v-button
                v-if="
                  item.selectList.length > 1 &&
                  index == item.selectList.length - 1
                "
                type="success"
                text="删除"
                @click="toDeleteSelect"
              />
              <v-button
                v-if="index == item.selectList.length - 1"
                text="新增"
                type="success"
                @click="toAddSelectItem"
              />
            </div>
          </div>
        </el-form-item>
        <el-form-item label="答案">
          <v-select
            placeholder="请选择答案"
            :multiple="item.problemType == 2 ? true : false"
            :options="item.selectList"
            v-model="item.rightAnswers"
          />
        </el-form-item>
        <el-form-item label="分数">
          <v-input
            type="number"
            placeholder="请输入分数"
            v-model="item.score"
          ></v-input>
        </el-form-item>
      </v-dialog>
    </form-panel>
  </div>
</template>

<script>
import {
  questionnaireDetailsUrl,
  deleteQuestionnaireProblemUrl,
  addQuestionnaireUrl,
  compileQuestionnaireUrl,
} from "./api.js";
export default {
  data() {
    return {
      letterList: [],
      statusType: [
        {
          value: 1,
          label: "已发布",
        },
        {
          value: 0,
          label: "待发布",
        },
      ],
      quType: [
        {
          value: 1,
          label: "单选",
        },
        {
          value: 2,
          label: "多选",
        },
        // {
        //   value: 3,
        //   label: "日期选择",
        // },
      ],
      form: {
        questionnaireName: null,
        requestList: [],
        status: null,
        id: null,
      },
      item: {
        problemName: "",
        problemType: null,
        optionValue: "",
        rightAnswers: null,
        score: "",
        selectList: [],
      },
      rightAnswersList: [],
      headers: [
        {
          prop: "problemName",
          label: "问题",
        },
        {
          prop: "problemType",
          label: "类型",
          formatter: (row, prop) => {
            if (row[prop] == 1) {
              return "单选";
            } else {
              return "多选";
            }
          },
        },
        {
          prop: "optionValue",
          label: "选项",
        },
        {
          prop: "rightAnswers",
          label: "答案",
        },
        {
          prop: "score",
          label: "分值",
        },
      ],
      id: null,
      isdialog: false,
      title: "",
      oIndex: "",
      isEdit: false,
    };
  },
  created() {
    this.getLetterList();
  },
  mounted() {
    const { id } = this.$route.query;
    this.id = id;
    if (id) {
      this.getDetail();
    }
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    toDeleteSelect() {
      this.item.selectList.pop();
    },
    toAddSelectItem() {
      for (var i = 0; i <= this.item.selectList.length - 1; i++) {
        if (!this.item.selectList[i].value) {
          this.$message.info("请填完选项");
          return;
        }
      }
      this.item.selectList.push({ value: "" });
    },
    // 保存
    submit() {
      if (!this.form.questionnaireName) {
        this.$message.error("请输入问卷名称");
        return;
      }
      if (!this.form.status) {
        this.$message.error("请选择发布状态");
        return;
      }
      if (this.form.requestList.length == 0) {
        this.$message.error("请添加问题");
        return;
      }
      let url = this.id ? compileQuestionnaireUrl : addQuestionnaireUrl;
      let params = {
        id: this.id,
        questionnaireName: this.form.questionnaireName,
        status: this.form.status,
        requestList: this.form.requestList,
      };
      this.$axios.post(url, params).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.$router.go(-1);
        }
      });
    },
    cancel() {},
    confirm() {
      if (!this.item.problemName) {
        this.$message.info("请填写名称");
        return;
      }
      if (!this.item.problemType) {
        this.$message.info("请选择类型");
        return;
      }
      for (var i = 0; i <= this.item.selectList.length - 1; i++) {
        if (!this.item.selectList[i].value) {
          this.$message.info("请填完选项");
          return;
        }
      }
      if (!this.item.rightAnswers) {
        this.$message.info("请填写答案");
        return;
      }
      if (!this.item.score) {
        this.$message.info("请填写分数");
        return;
      }
      this.item.optionValue = this.item.selectList
        .map((ele) => ele.value)
        .join(",");
      if (this.item.problemType == 2) {
        this.item.rightAnswers = this.item.rightAnswers.join(",");
      }
      if (this.isEdit) {
        this.$set(this.form.requestList, this.oIndex, this.item);
      } else {
        this.form.requestList.push(this.item);
      }
      this.isdialog = false;
    },
    changeQuType(value) {
      console.log(value);
      if (value == 1) {
        this.item.rightAnswers = "";
      } else if (value == 2) {
        this.item.rightAnswers = [];
      }
    },
    // 获取问卷详情
    getDetail() {
      let params = {
        id: this.id,
      };
      this.$axios
        .get(`${questionnaireDetailsUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code == 200) {
            console.log(res.data.detailsVOS);
            this.form.requestList = res.data.detailsVOS;
            this.form.id = res.data.id;
            this.form.questionnaireName = res.data.questionnaireName;
            this.form.status = res.data.status;
          }
        });
    },
    //新增问题
    toAddSelect() {
      this.title = "新增问题";
      this.isEdit = false;
      this.oIndex = "";
      this.isdialog = true;
      this.item = {
        problemName: "",
        problemType: "",
        optionValue: "",
        rightAnswers: null,
        score: "",
        selectList: [{ value: "" }],
      };
    },
    // 编辑
    toEdit(row, index) {
      this.title = "编辑问题";
      this.oIndex = index;
      this.isEdit = true;
      row.selectList = [];
      if (row.optionValue) {
        let arr = row.optionValue.split(",");
        arr.forEach((ele) => {
          row.selectList.push({ value: ele });
        });
      }
      console.log(row.rightAnswers);
      let obj = JSON.stringify(row);
      this.item = JSON.parse(obj);
      this.item.rightAnswers = null;
      if (this.item.problemType == 2) {
        this.item.rightAnswers = row.rightAnswers.split(",");
      } else {
        this.item.rightAnswers = row.rightAnswers;
      }
      this.isdialog = true;
    },
    // 删除
    toDelete(item, index) {
      // if (this.id) {
      //   this.$confirm("是否确定删除?", "提示", {
      //     confirmButtonText: "确定",
      //     cancelButtonText: "取消",
      //     type: "warning",
      //   }).then(() => {
      //     let params = {
      //       problemId: item.problemId,
      //     };
      //     this.$axios
      //       .post(
      //         `${deleteQuestionnaireProblemUrl}`,
      //         this.$qs.stringify(params)
      //       )
      //       .then((res) => {
      //         if (res.code === 200) {
      //           this.getDetail();
      //         }
      //       });
      //   });
      // } else {
      this.form.requestList.splice(index, 1);
      // }
    },
    getLetterList() {
      for (var i = 0; i < 26; i++) {
        this.letterList.push(String.fromCharCode(65 + i));
      }
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  box-sizing: border-box;
  height: 100%;
  .addBtn {
    padding: 0 40px;
  }
  .workSheet {
    padding: 40px;
    height: 400px;
    overflow-y: auto;
  }
  .handle {
    margin: 10px 0 0;
    text-align: right;
    .v-button {
      margin-left: 10px;
    }
  }
}
</style>